import React, {useState, useEffect} from 'react';
import { FiMenu } from 'react-icons/fi';
import JournalPane from './JournalEntry/JournalPane';
import ResponsePane from './Response/ResponsePane';
import TitleBar from "./Menu/TitleBar";
import './App.css';
import FirebaseAuthentication from './Firebase/FirebaseAuthentication';
import {Route, Link, useNavigate, Routes} from 'react-router-dom'
import SignIn from './Firebase/SignIn';
import { firestoreManager } from './Firebase/FirestoreManager';
import Journey from "./Journey/Journey";
import MyGoals from "./Goals/MyGoals";
import NavigationContext from "./Navigation/NavigationContext";
import { analysisManager } from "./Analysis/AnalysisManager";
import { toast, ToastContainer, Bounce } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";


const App = () => {
    const [responses, setResponses] = useState([]);
    const [darkMode, setDarkMode] = useState(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches);
    const [entries, setEntries] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isAnalyzing, setIsAnalyzing] = useState(false);
    const [selectedModel, setSelectedModel] = useState('chatgpt');
    const [currentUser, setCurrentUser] = useState(null);
    const [entryId, setEntryId] = useState(null);
    const [entry, setEntry] = useState("");
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [fromJourney, setFromJourney] = useState(false);
    const [isDiscussion, setIsDiscussion] = useState(false);
    const [greetingFetched, setGreetingFetched] = useState(false);
    const [selectedPersonality, setSelectedPersonality] = useState("quilly");

    useEffect(() => {
            FirebaseAuthentication.onAuthStateChanged(user => {
                setCurrentUser(user);
                setResponses([]); // Reset responses when user changes
                setGreetingFetched(false); // Reset greeting fetched state
            });
        }, []);

    useEffect(() => {
            const checkEntriesAndFetchGreeting = async () => {
                if (currentUser?.uid) {
                    try {
                        setIsLoading(true); // Ensure loading is indicated
                        const data = await firestoreManager.getRecentEntries(currentUser.uid, 5);
                        if (data.length > 0) {
                            console.log('Entries exist. Fetching dynamic greeting...');
                            await fetchGreeting(true, data);
                            setEntries(data);
                        } else {
                            console.log('No entries found. Fetching generic greeting...');
                            await fetchGreeting(false); // Generic greeting
                        }
                    } finally {
                        setIsLoading(false); // Set loading false when all operations are complete
                    }
                }
            };

            checkEntriesAndFetchGreeting();
        }, [currentUser?.uid]);

    const handleSetEntryId = (id) => {
        setEntryId(id);
    };

    const handleSetEntry = (entry) => {
        setEntry(entry);
    };

    const handlePersonalityChange = (event) => {
        setSelectedPersonality(event.target.value);
    };

    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

    const handleLogout = async () => {
        try {
            await FirebaseAuthentication.signOut();
            // After logging out, you can also manually set currentUser to null if needed
            setCurrentUser(null);
            console.log("Logged out successfully");
        } catch (error) {
            console.error("Logout failed:", error);
        }
    };

    const sendToast = (message) => {
        toast.success(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Bounce,
        });
    }

    const handleSaveJournalSession = async () => {
        if (!entryId) {
            console.error("No entry ID found. Cannot save session.");
            return;
        }

        try {
            sendToast('📖 Submitting journal entry for analysis on all your feels 🔬');
            await setEntryId(firestoreManager.createEntry(currentUser?.uid, entry, entryId)?.uid);
            await analysisManager.performSentimentAnalysis(selectedModel, entry, entryId);
            await analysisManager.performEmotionAnalysis(selectedModel, entry, entryId);
            await analysisManager.performTopicAnalysis(selectedModel, entry, entryId);
            sendToast('📖 Journal entry has been analyzed and saved!💾');
            console.log("Journal session saved successfully.");
        } catch (error) {
            console.error("Failed to save journal session:", error);
        }
    };

    const handleMyJourneyMenuClick = async () => {
        toggleMenu();
        setIsDiscussion(false);
    }

    const handleSendEntry = async (entry) => {
        setIsAnalyzing(true);
        try {
            const response = await fetch('https://aspireai-418902.ue.r.appspot.com/entry/analyze', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    entry,
                    modelType: selectedModel,
                    personalityType: selectedPersonality, // Add personalityType
                }),
            });

            const data = await response.json();
            if (response.ok) {
                await handleQuillyResponse(entryId, data);
            } else {
                console.error('Error from server', data);
            }
        } catch (error) {
            console.error('Error sending entry', error);
        } finally {
            setIsAnalyzing(false);
        }
    };

    const handleQuillyResponse = async (entryId, quillyResponse, createEntry = false) => {
        console.log("Quilly's response saved to Firestore");
        if (!quillyResponse.message) {
            sendToast('🦉 Quilly didn\'t think he had anything valuable to add. You can prod him with the \'Call Quilly\' button.🦉');
        }
        setResponses(prevResponses => [...prevResponses, { type: 'quilly', message: quillyResponse.message }]);

        if (createEntry) {
            return firestoreManager.createQuillyResponse(entryId, quillyResponse.message);
        }
    };

    const handleUserResponse = async (entryId, userResponse, createEntry = false) => {
        console.log("User's response saved to Firestore");
        setResponses(prevResponses => [...prevResponses, { type: 'user', message: userResponse.message }]);

        if (createEntry) {
            return firestoreManager.createUserResponse(entryId, userResponse.message);
        }
    }

    const handleUserMessage = async (userInput) => {
        setIsAnalyzing(true);
        const userResponseObject = { type: 'user', message: userInput };
        const updatedResponses = [...responses, userResponseObject];

        try {
            await handleUserResponse(entryId, userResponseObject);

            const bodyContent = {
                modelType: selectedModel,
                responses: updatedResponses,
                entry,
                personalityType: selectedPersonality, // Add personalityType
            };

            if (entries.length > 0) {
                bodyContent.pastEntries = entries.map((entry) => entry.content);
            }

            const response = await fetch('https://aspireai-418902.ue.r.appspot.com/chat/analyze', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(bodyContent),
            });

            const data = await response.json();
            if (response.ok) {
                await handleQuillyResponse(entryId, data);
            } else {
                console.error('Response from Quilly was not ok:', response.statusText);
            }
        } catch (error) {
            console.error('Error sending message to Quilly:', error);
        } finally {
            setIsAnalyzing(false);
        }
    };

    const navigate = useNavigate();

    const handleDiscussWithQuilly = async (entry, handleDiscussIsLoading) => {
        setResponses([]);
        handleDiscussIsLoading(true);
        setIsDiscussion(true);
        try {
            const response = await fetch('https://aspireai-418902.ue.r.appspot.com/entry/discuss', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    entry: entry.content,
                    entryDate: entry.created_at,
                    modelType: selectedModel,
                    personalityType: selectedPersonality,
                }),
            });

            const result = await response.json();
            setEntry(entry.content);
            setResponses((prevResponses) => [
                ...prevResponses,
                { type: 'quilly', message: result.response },
            ]);
            navigate('/');
        } catch (error) {
            console.error('Error discussing with Quilly:', error);
        }
    };

    const fetchGreeting = async (isDynamic, entries = []) => {
        const bodyContent = {
            modelType: selectedModel,
            personalityType: selectedPersonality, // Add personalityType
        };

        if (isDynamic) {
            bodyContent.username = currentUser?.email;
            bodyContent.entries = entries.map((entry) => entry.content);
        }

        try {
            const response = await fetch('https://aspireai-418902.ue.r.appspot.com/greet_user', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(bodyContent),
            });

            const data = await response.json();
            setResponses((prevResponses) => [
                ...prevResponses,
                { type: 'quilly', message: data.message },
            ]);
        } catch (error) {
            console.error('Failed to fetch greeting:', error);
        }
    };

    const handleModelChange = (event) => {
        setSelectedModel(event.target.value);
    };

    const toggleDarkMode = () =>{
        setDarkMode(!darkMode);
        toggleMenu();
    }

    const renderMainUI = () => {
        return (
            <>
            <ToastContainer
                position="top-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
                <div className="MainContent">
                    <div className="JournalPane">
                        <JournalPane
                            userId={currentUser?.uid}
                            entry={entry}
                            setEntryId={handleSetEntryId}
                            setEntry={handleSetEntry}
                            onSendEntry={handleSendEntry}
                            isDiscussion={isDiscussion}
                            onSaveEntry={handleSaveJournalSession}
                        />
                    </div>
                    <div className="ResponsePane">
                        <ResponsePane
                            responses={responses}
                            isLoading={isLoading}
                            isAnalyzing={isAnalyzing}
                            onUserSubmit={handleUserMessage}
                        />
                    </div>
                </div>
            </>
        )
    }

    return (
        <div className={`App ${darkMode ? 'dark-mode' : ''}`}>
            <div className="TitleBar">
                <TitleBar username={currentUser?.email}></TitleBar>
                <div className="Menu">
                    <div className="HamburgerMenu" onClick={toggleMenu}>
                        ☰
                    </div>
                    <div className={isMenuOpen ? "MenuItemsActive" : "MenuItems"}>
                        <Link to="/" className="MenuButton" onClick={handleMyJourneyMenuClick}>My Journal</Link>
                        <Link to="/journey" className="MenuButton" onClick={toggleMenu}>My Journey</Link>
                        <button onClick={toggleDarkMode}>Toggle Dark Mode</button>
                        <button>Settings, eventually</button>
                        <button onClick={handleLogout}>Logout</button>
                        <div className="PersonalitySelector">
                            <label htmlFor="personality">Personality:</label>
                            <select
                                id="personality"
                                value={selectedPersonality}
                                onChange={handlePersonalityChange}
                            >
                                <option value="quilly">Quilly (Compassionate & Insightful)</option>
                                <option value="stoic_philosopher">Stoic Philosopher</option>
                                <option value="playful_cheerleader">Playful Cheerleader</option>
                                <option value="empathetic_listener">Empathetic Listener</option>
                                <option value="sarcastic_genius">Sarcastic Genius</option>
                                <option value="comedian">Comedian</option>
                                <option value="sassy_best_friend">Sassy Best Friend</option>
                                <option value="literal_robot">Literal Robot</option>
                                <option value="chill_surfer_dude">Chill Surfer Dude</option>
                                <option value="melodramatic_poet">Melodramatic Poet</option>
                                <option value="clueless_grandpa">Clueless Grandpa</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            {!currentUser ? (
                <SignIn/>
            ) : (
                <NavigationContext.Provider value={{fromJourney, setFromJourney}}>
                    <Routes>
                        <Route path="/" element={renderMainUI()}/>
                        <Route path="/journey" element={<Journey user={currentUser} selectedModel={selectedModel}
                                                                 handleDiscussWithQuilly={handleDiscussWithQuilly}/>}/>
                        <Route path="/goals" element={<MyGoals user={currentUser} selectedModel={selectedModel}/>}/>
                    </Routes>
                </NavigationContext.Provider>
            )}
        </div>
    );
}

export default App;