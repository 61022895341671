import React, { useState, useEffect } from 'react';
import DOMPurify from 'dompurify';
import PulseLoader from 'react-spinners/PulseLoader';
import { startOfWeek, endOfWeek, startOfMonth } from "date-fns";
import { firestoreManager } from '../../Firebase/FirestoreManager';
import MyGoals from "../../Goals/MyGoals";
import './SummaryandPlan.css';

const SummaryAndPlan = ({ user, selectedModel, entries, handleDiscussWithQuilly }) => {
  // State variables
  const [summary, setSummary] = useState("");         // Holds the *raw* summary text we display
  const [summaries, setSummaries] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [showSummary, setShowSummary] = useState(true);

  const [isLoading, setIsLoading] = useState(false);
  const [goalsAreLoading, setGoalsAreLoading] = useState(false);
  const [goalsLoadingSummaryId, setGoalsLoadingSummaryId] = useState(null);

  const [userInput, setUserInput] = useState('');
  const [goalsForSummaries, setGoalsForSummaries] = useState({});
  const [goalsVisibility, setGoalsVisibility] = useState({});

  // On component mount or user change, load existing summaries
  useEffect(() => {
    const loadSummaries = async () => {
      if (user?.uid) {
        const data = await firestoreManager.get_summaries(user?.uid);

        // Sort chronologically reversed
        const sortedData = data.sort((a, b) => b.date_start - a.date_start);
        setSummaries(sortedData);

        // Load goals for each summary
        const goalsData = {};
        for (const sumObj of sortedData) {
          const goals = await firestoreManager.get_goals(sumObj.id, user?.uid);
          goalsData[sumObj.id] = goals;
        }
        setGoalsForSummaries(goalsData);
      }
    };
    loadSummaries();
  }, [user?.uid]);

  // 1) Step: Convert <br> and <p> tags to \n, then sanitize, then store.
  const convertHtmlToNewlines = (htmlString) => {
    // Replace <br> or <br/> with \n
    let temp = htmlString.replace(/<br\s*\/?>/gi, '\n');

    // Replace paragraph tags with newlines (remove closing tags)
    temp = temp.replace(/<p>/gi, '\n').replace(/<\/p>/gi, '');

    // Optionally remove any remaining HTML tags, or allow them
    // Here we do a minimal DOMPurify pass to remove scripts, etc.
    let sanitized = DOMPurify.sanitize(temp, { ALLOWED_TAGS: [], ALLOWED_ATTR: [] });

    // This should leave you with mostly plain text (plus newlines)
    return sanitized;
  };

  // 2) Our enhanced FormattedText component:
  const FormattedText = ({ text }) => {
    const formatText = (inputText) => {
      // Split on \n to get lines
      const lines = inputText.split('\n');
      const formattedElements = [];

      let bulletBuffer = [];
      let isBulletListOpen = false;

      // Helper to flush bullets into a <ul>
      const flushBulletList = (keyPrefix) => {
        if (bulletBuffer.length > 0) {
          formattedElements.push(
            <ul key={keyPrefix}>
              {bulletBuffer.map((item, i) => (
                <li key={i} dangerouslySetInnerHTML={{ __html: item }} />
              ))}
            </ul>
          );
          bulletBuffer = [];
        }
      };

      lines.forEach((line, index) => {
        let trimmedLine = line.trim();

        if (!trimmedLine) {
          // Empty line -> end any bullet list, then line break
          if (isBulletListOpen) {
            flushBulletList(`bullets-${index}`);
            isBulletListOpen = false;
          }
          formattedElements.push(<br key={`br-${index}`} />);
          return;
        }

        // Convert **bold** to <strong>
        trimmedLine = trimmedLine.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");

        // 1) Headings that start with ### (optionally ending with a colon)
        if (/^###\s?(.+):?$/.test(trimmedLine)) {
          if (isBulletListOpen) {
            flushBulletList(`bullets-${index}`);
            isBulletListOpen = false;
          }
          const headingText = trimmedLine.match(/^###\s?(.+):?$/)[1].trim();
          formattedElements.push(
            <h3 key={index} dangerouslySetInnerHTML={{ __html: headingText }} />
          );
          return;
        }

        // 2) Numbered items, e.g. "1. Something:" or "2. Something"
        if (/^\d+\.\s.+/.test(trimmedLine)) {
          if (isBulletListOpen) {
            flushBulletList(`bullets-${index}`);
            isBulletListOpen = false;
          }
          // Remove "1. " from the front
          let itemText = trimmedLine.replace(/^\d+\.\s?/, "");
          // If there's a trailing colon, remove it
          itemText = itemText.replace(/:$/, "").trim();
          // Display as bold subheading
          formattedElements.push(<p key={index}><strong>{itemText}</strong></p>);
          return;
        }

        // 3) Bullets: lines beginning with "- "
        if (/^- /.test(trimmedLine)) {
          const bulletText = trimmedLine.replace(/^- /, "");
          if (!isBulletListOpen) {
            isBulletListOpen = true;
          }
          bulletBuffer.push(bulletText);
          return;
        }

        // 4) Default: plain paragraph
        if (isBulletListOpen) {
          flushBulletList(`bullets-${index}`);
          isBulletListOpen = false;
        }
        formattedElements.push(
          <p key={index} dangerouslySetInnerHTML={{ __html: trimmedLine }} />
        );
      });

      // Flush leftover bullets
      if (isBulletListOpen) {
        flushBulletList("end");
        isBulletListOpen = false;
      }
      return formattedElements;
    };

    return (
      <div className="formatted-text-container">
        {formatText(text)}
      </div>
    );
  };

  // Summaries can have multiple entries. Use handleViewSummary to set the single summary to show
  const handleViewSummary = (summaryObj) => {
    // Convert any HTML breaks <br> / <p> to \n, then store that text
    const rawText = convertHtmlToNewlines(summaryObj.summary);
    setSummary(rawText);
    toggleSummary();
  };

  const toggleSummary = () => {
    setShowSummary(!showSummary);
  };

  const handleGenerateGoals = async (summaryObj) => {
    setGoalsAreLoading(true);
    try {
      const response = await fetch('https://aspireai-418902.ue.r.appspot.com/goals/weekly', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ summary: summaryObj, modelType: selectedModel }),
      });
      if (response.ok) {
        const data = await response.json();
        const goalsString = data.goals;
        const parsedGoals = JSON.parse(goalsString);
        const goalsData = parsedGoals.goals;
        if (Array.isArray(goalsData)) {
          // Save the goals to Firestore
          for (const goal of goalsData) {
            const goalTitle = Object.values(goal)[0];
            const goalDescription = goal.description;
            await firestoreManager.create_goal(
              user?.uid,
              summaryObj.id,
              'weekly',
              goalTitle,
              goalDescription
            );
          }
          // Reload the saved goals from Firestore
          const savedGoals = await firestoreManager.get_goals(summaryObj.id, user?.uid);
          setGoalsForSummaries(prev => ({
            ...prev,
            [summaryObj.id]: savedGoals.map(g => ({ ...g, visible: true })),
          }));
          setGoalsVisibility(prev => ({
            ...prev,
            [summaryObj.id]: true,
          }));
        }
      } else {
        console.error('Error fetching goals:', response.status);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setGoalsAreLoading(false);
    }
  };

  // Example function to generate summary from your entries
  const generateSummary = async () => {
    setIsLoading(true);
    // Filter entries by date, etc.
    try {
      const response = await fetch('https://aspireai-418902.ue.r.appspot.com/trends/summary', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          entries,
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
          modelType: selectedModel
        }),
      });
      const result = await response.json();

      // result.summary might be HTML with <br> or <p>...
      // Convert that to text with \n
      const cleanedText = convertHtmlToNewlines(result.summary);
      setSummary(cleanedText);

      // Save to Firestore as raw text or as HTML, depending on your preference
      await firestoreManager.create_summary(
        user?.uid,
        startDate,
        endDate,
        result.summary,  // or cleanedText
        false,
        entries.map(obj => obj.id)
      );
    } catch (error) {
      console.error('Error generating summary:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // For demonstration, if your summary text is multiline with markers, the formatting will be visible
  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Example chat submission
    setUserInput('');
  };

  // Filter entries by date if needed
  // const filterEntriesByDate = ...

  return (
    <div className="SummaryPlanContainer">
      <div className="DatePickerContainer">
        <input
          className="DatePicker"
          type="date"
          value={startDate.toISOString().substring(0, 10)}
          onChange={(e) => setStartDate(new Date(e.target.value))}
        />
        <input
          className="DatePicker"
          type="date"
          value={endDate.toISOString().substring(0, 10)}
          onChange={(e) => setEndDate(new Date(e.target.value))}
        />
        <div className="GenerateButtonWrapper">
          {isLoading ? (
            <div className="GenerateButtonOverlay loading">
              <PulseLoader color="white" />
            </div>
          ) : null}
          <button className="GenerateButton" onClick={generateSummary}>
            Generate Summary
          </button>
        </div>
      </div>

      {/* Render your Firestore summaries */}
      {summaries.map((sumObj, index) => (
        <div className="EntryCard" key={index}>
          <div className="EntryHeader">
            {/* date_start is a Firestore Timestamp? Convert to Date for .toLocaleString() */}
            {sumObj.date_start.toDate().toLocaleString()}
          </div>
          <div className="EntryContent">
            {sumObj.summary?.substring(0, 200)}...
          </div>
          <div className="EntryMetaData">
            <div>Entries in Summary: {sumObj.entries.join(', ')}</div>
          </div>
          <div className="GenerateButtonWrapper">
            {goalsLoadingSummaryId === sumObj.id && goalsAreLoading ? (
              <div className="GenerateButtonOverlay loading">
                <PulseLoader color="white" />
              </div>
            ) : null}
            <button
              className="ViewDetailsButton"
              onClick={() => {
                if (goalsForSummaries[sumObj.id] && goalsForSummaries[sumObj.id].length > 0) {
                  // Toggle goals visibility
                  setGoalsVisibility(prev => ({
                    ...prev,
                    [sumObj.id]: !prev[sumObj.id],
                  }));
                } else {
                  // Generate new goals if none exist
                  setGoalsLoadingSummaryId(sumObj.id);
                  handleGenerateGoals(sumObj);
                }
              }}
            >
              {goalsForSummaries[sumObj.id] && goalsForSummaries[sumObj.id].length > 0
                ? goalsVisibility[sumObj.id]
                  ? 'Hide Goals'
                  : 'View Goals'
                : 'Generate Goals'
              }
            </button>
          </div>
          <button
            className="ViewDetailsButton"
            onClick={() => handleViewSummary(sumObj)}
          >
            {showSummary ? "Hide Summary" : "Show Summary"}
          </button>

          {/* If showSummary is true, display the parsed version of the summary. */}
          {showSummary && summary && (
            <div className="Content">
              <div className="SummarySection">
                <h3>Summary</h3>
                <FormattedText text={summary} />
              </div>
            </div>
          )}

          {goalsVisibility[sumObj.id] && (
            <MyGoals goals={goalsForSummaries[sumObj.id]} />
          )}

          <div className="ChatContainer">
            <h3>Chat with Quilly about your Week</h3>
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                value={userInput}
                onChange={handleInputChange}
                placeholder="Type your message..."
              />
              <button type="submit">Send</button>
            </form>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SummaryAndPlan;