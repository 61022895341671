import { db } from './firebase-config';
import { collection, addDoc, doc, updateDoc, setDoc, deleteDoc, getDoc, getDocs, limit, orderBy, query, where } from "firebase/firestore";

class FirestoreManager {
    constructor() {
        this.journalEntriesRef = collection(db, "journal_entries");
        this.userResponsesRef = collection(db, "user_responses");
        this.quillyResponsesRef = collection(db, "quilly_responses");
        this.summariesRef = collection(db, "summaries");
        this.goalsRef = collection(db, "goals");
    }

    // Create a new journal entry and return its document reference
    async createEntry(userId, initialContent = "", entryId) {
        let docRef = doc(this.journalEntriesRef, entryId);
        docRef = await setDoc(docRef, {
            user_id: userId,
            content: initialContent,
            created_at: new Date(),
            updated_at: new Date(),
        });
        return docRef;
    }

    async getRecentEntries(user, n) {
            const recentEntriesQuery = query(
                this.journalEntriesRef,
                where("user_id", "==", user),
                orderBy("created_at", "desc"),
                limit(n)
            );

            try {
                const querySnapshot = await getDocs(recentEntriesQuery);
                const entries = [];
                querySnapshot.forEach(doc => {
                    entries.push({ id: doc.id, ...doc.data() });
                });
                return entries;
            } catch (error) {
                console.error("Error fetching recent entries: ", error);
                return [];
            }
        }

    // Update an existing journal entry
    async updateEntry(entryId, newContent) {
        const docRef = doc(this.journalEntriesRef, entryId);
        await updateDoc(docRef, {
            content: newContent,
            updated_at: new Date(),
        });
        console.log("Journal entry updated.");
    }

    async updateEntrySentiment(entryId, sentiment) {
        const docRef = doc(this.journalEntriesRef, entryId);
        await updateDoc(docRef, {
            sentiment: sentiment,
            updated_at: new Date(),
        });
        console.log("Journal sentiment updated.");
    }

    async updateEntryEmotions(entryId, emotions) {
        const docRef = doc(this.journalEntriesRef, entryId);
        await updateDoc(docRef, {
            emotions: emotions.split(','),
            updated_at: new Date(),
        });
        console.log("Journal emotions updated.");
    }

    async updateEntryTopics(entryId, topics) {
        const docRef = doc(this.journalEntriesRef, entryId);
        await updateDoc(docRef, {
            topics: topics.split(','),
            updated_at: new Date(),
        });
        console.log("Journal topics updated.");
    }

    async create_summary(userId, dateStart, dateEnd, summary, isMonthly, entryIds) {
        try {
            const docRef = await addDoc(this.summariesRef, {
                    user_id: userId,
                    date_start: dateStart,
                    date_end: dateEnd,
                    summary: summary,
                    is_monthly: isMonthly,
                    entries: entryIds
                });
                console.log(`Document with ID: ${docRef.id} created`);
                console.log(`Summary for date range: ${dateStart} - ${dateEnd} has been saved`);
            } catch (error) {
                console.error("Error adding document: ", error);
            }
    }

    async get_summaries(userId) {
        const q = query(this.summariesRef, where("user_id", "==", userId));
        const querySnapshot = await getDocs(q);
        const summaries = [];

        querySnapshot.forEach((doc) => {
            summaries.push({ id: doc.id, ...doc.data() });
        });
        return summaries;
    }

    async create_goal(userId, summaryId, type, title, description) {
        try {
            const docRef = await addDoc(this.goalsRef, {
                user_id: userId,
                summary_id: summaryId,
                type: type,
                title: title,
                description: description
                });
                console.log(`goal with ID: ${docRef.id} created`);
            } catch (error) {
                console.error("Error adding document: ", error);
            }
    }

    async get_goals(summaryId, userId) {
        const q = query(this.goalsRef, where("summary_id", "==", summaryId), where("user_id", "==", userId));
        const querySnapshot = await getDocs(q);
        const goals = [];

        querySnapshot.forEach((doc) => {
            goals.push({ id: doc.id, ...doc.data() });
        });
        return goals;
    }

    // Delete a journal entry
    async deleteEntry(entryId) {
        await deleteDoc(doc(this.journalEntriesRef, entryId));
        console.log("Journal entry deleted.");
    }

    // Retrieve a single journal entry by its ID
    async getEntry(entryId) {
        const docRef = doc(this.journalEntriesRef, entryId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            console.log("Journal entry:", docSnap.data());
            return docSnap.data();
        } else {
            console.log("No such document!");
            return null;
        }
    }

    // Retrieve all journal entries for a specific user
    async getUserEntries(userId) {
        const q = query(this.journalEntriesRef, where("user_id", "==", userId));
        const querySnapshot = await getDocs(q);
        const entries = [];
        querySnapshot.forEach((doc) => {
            entries.push({ id: doc.id, ...doc.data() });
        });
        return entries;
    }

    // Create a new user response
    async createUserResponse(journalEntryId, content) {
        const docRef = await addDoc(this.userResponsesRef, {
            journal_entry_id: journalEntryId,
            content: content,
            created_at: new Date()
        });
        console.log("User response created with ID: ", docRef.id);
        return docRef;
    }

    // Create a new Quilly response
    async createQuillyResponse(journalEntryId, content) {
        const docRef = await addDoc(this.quillyResponsesRef, {
            journal_entry_id: journalEntryId,
            content: content,
            created_at: new Date()
        });
        console.log("Quilly response created with ID: ", docRef.id);
        return docRef;
    }
}

// Exporting an instance of FirestoreManager for use throughout the app
export const firestoreManager = new FirestoreManager();
